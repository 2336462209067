<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > <router-link :to="{path:'/reservation'}">预约服务</router-link> > {{getIndex(type)}}</div>
			<img src="@/assets/images/step2.png"/>
			<div class="content">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
					<el-form-item v-if="type == 2" label="参观单位" prop="visitingUnit">
						<el-input v-model="ruleForm.visitingUnit" clearable></el-input>
					</el-form-item>
					<el-form-item :label="type == 2 ? '联系人姓名': '姓名'" prop="name">
						<el-input v-model="ruleForm.name" clearable></el-input>
					</el-form-item>
					<el-form-item :label="type == 2 ? '联系人身份证号': '身份证号'" prop="idNo">
						<el-input v-model="ruleForm.idNo" maxlength="20" clearable></el-input>
					</el-form-item>
					<el-form-item :label="type == 2 ? '联系人手机号码': '手机号码'" prop="phone">
						<el-input v-model.number="ruleForm.phone"  maxlength="11" clearable></el-input>
					</el-form-item>
					<div v-if="type == 1 || type == 2">
						<el-form-item label="团队参观人数"  prop="teamNums">
							<div class="team">
								<el-input v-model.number="ruleForm.teamNums" clearable></el-input><span v-if="type == 1 && tips" class="tips">团队预约需9人以上，少于9人请进行个人预约</span>
							</div>
						</el-form-item>
					</div>
<!--					<el-form-item label="是否需要讲解员" prop="commentator">-->
<!--						<el-radio-group v-model="ruleForm.commentator" @change="handleChangeRadio">-->
<!--							<el-radio label="1">是</el-radio>-->
<!--							<el-radio label="2">否</el-radio>-->
<!--						</el-radio-group>-->
<!--					</el-form-item>-->
<!--					<el-form-item label="" v-show="ruleForm.commentator == 1">-->
<!--						<div class="btnList">-->
<!--							<span :class="{'checked' : ruleForm.explainProject == 1}" @click="handleChangeType(1)">全园讲解-->
<!--								&lt;!&ndash; {{type == 2 ? '(免费)' : '￥200'}} &ndash;&gt;-->
<!--							</span>-->
<!--							<span :class="{'checked' : ruleForm.explainProject == 2}" @click="handleChangeType(2)">纪念馆讲解-->
<!--								&lt;!&ndash; {{type == 2 ? '(免费)' : '￥100'}} &ndash;&gt;-->
<!--							</span>-->
<!--						</div>-->
<!--					</el-form-item>-->
					<el-form-item v-if="type == 2" label="上传公函/介绍信（加盖公章）" prop="officialLetter">
						<div class="flex_a">
							<img class="uploadImg" v-if="ruleForm.officialLetter" :src="ruleForm.officialLetter"/>
							<el-upload
								v-loading="loading"
								class="avatar-uploader"
								:action="uploadUrl"
								:show-file-list="false"
								:on-progress="handleLoading"
								:on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload">
								<img  :src="uploadImg" class="avatar">
							</el-upload>


						</div>
					</el-form-item>
					<el-form-item v-if="type == 1 || type == 2" label="备注">
						<el-input v-model.number="ruleForm.remark"  clearable></el-input>
					</el-form-item>
					<div class="btn">
						<el-button type="primary" @click="submitForm('ruleForm')">下一步</el-button>
					</div>
				</el-form>


			</div>


		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			var checkidNo = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入身份证号'));
				}
				console.log(value.length);
				if(value.length != 18){
					return callback(new Error('身份证号必须18位数'));
				}else{
					callback()
				}

			};
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入手机号码'));
				}
				if (!Number.isInteger(value)) {
					callback(new Error('手机号码必须为数字值'));
				} else {
					let myreg=/^[1][3,4,5,6.7,8,9][0-9]{9}$/;
					if (!myreg.test(value)) {
						callback(new Error('手机号格式不正确'));
						return
					} else {
						callback();
					}
				}
			};
			var checkTeamNums = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入团队参观人数'));
				}
				if (!Number.isInteger(value)) {
					callback(new Error('团队参观人数必须为数字值'));
				} else {
					if (this.type == 1 && value < 9) {
						this.tips = true
						return
					} else {
						this.tips = false
						callback();
					}
				}
			};
			return {
				loading: false,
				type: null,
				ruleForm: {
					visitingUnit: '',
					name: '',
					idNo: '',
					phone: '',
					commentator: '2',
					imageUrl: '',
					explainProject: 1,
					bookingDate: '',
					bookingPeriodId: '',
					bookingTime: '',
					teamNums: '',
					officialLetter: '',
					officialLetterName: '',
					remark: '',
				},
				 rules: {
					visitingUnit: [
						{ required: true, message: '请输入参观单位', trigger: 'blur' }
					],
					name: [
						{ required: true, message: '请输入姓名', trigger: 'blur' },
					],
					idNo: [
						{ required: true, validator: checkidNo, trigger: 'blur' }
						// { required: true, message: '请输入身份证号', trigger: 'blur' },
					],
					phone: [
						{ required: true, validator: checkPhone, trigger: 'blur' }
					],
					teamNums: [
						{ required: true, validator: checkTeamNums, trigger: 'blur' }
					],
					officialLetter: [
						{ required: true, message: '请上传公函', trigger: 'blur' }
					]

				},
				uploadImg: require('@/assets/images/tianjia.png'),
				tips: false,


			}
		},
		created() {
			console.log(urlobj.localUrl)

		},
		mounted() {
			this.type = this.$route.query.type
			this.ruleForm.bookingDate = this.$route.query.bookingDate
			this.ruleForm.bookingPeriodId = this.$route.query.bookingPeriodId
			this.ruleForm.bookingTime = this.$route.query.bookingTime
		},
		computed: {
			uploadUrl() {
				return urlobj.localUrl + '/baseApi/uploadFile'
			},
		},
		methods: {
			getIndex(index) {
				if(index == 0) return '个人预约'
				if(index == 1) return '团队预约'
				if(index == 2) return '政务接待'
			},
			handleChangeType(val) {
				this.ruleForm.explainProject = val
			},
			handleChangeRadio(val) {
				if(val == 1){
					 this.ruleForm.explainProject = 1
				}else{
					this.ruleForm.explainProject = 0
				}
				console.log(val)
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('上传封面图片只能是JPG或PNG格式!');
					return isJPG
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
					return isLt2M
				}
			},
			handleLoading() {
				this.loading = true
			},
			handleAvatarSuccess(res, file, fileList) {
				if(res.code === 0){
					this.loading = false
					this.$message.success('上传成功')
					this.ruleForm.officialLetter = res.data.domain + res.data.key
					this.ruleForm.officialLetterName = res.fileName
				}
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
				if (valid) {
					let obj = {
						usersId: JSON.parse(localStorage.getItem('userInfo')).id,
						type: this.type,
						bookingDate: this.ruleForm.bookingDate,
						bookingPeriodId: this.ruleForm.bookingPeriodId,
						bookingTime: this.ruleForm.bookingTime,
						name: this.ruleForm.name,
						idNo: this.ruleForm.idNo,
						phone: this.ruleForm.phone,
						explainProject: this.ruleForm.explainProject,
						visitingUnit: this.ruleForm.visitingUnit,
						teamNums: this.ruleForm.teamNums,
						officialLetter: this.ruleForm.officialLetter,
						officialLetterName: this.ruleForm.officialLetterName,
						remark: this.ruleForm.remark,
					}
					sessionStorage.setItem('form',JSON.stringify(obj))
					this.$router.push({ path: "/Reservation/step_three",query:{type:this.type}});
				} else {
					return false;
				}
				});
			},



		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		min-height: calc(100vh - 354px);
		background: #FFFFFF;
		padding-bottom: 60px;
		>img {
			margin: 0 auto;
			margin-bottom: 20px;
			margin-top: 50px;
		}
		.avatar-uploader{
			margin-left: 10px;
			width: 100px;
			height: 100px;
		}
		.uploadImg{
			width: 100px;
			height: 100px;
		}
		.content{
			min-height: 453px;
			width: 950px;
			background: url('../../assets/images/kuang.png') no-repeat;
			background-size: 100% 100%;
			margin: 0 auto;
			margin-top: 30px;
			padding: 40px 60px;
			font-size: 16px;
			color: #595E63;
			display: flex;
			align-items: center;
			justify-content: center;
			::v-deep .el-form-item__label{
				font-size: 16px;
			}
			::v-deep .el-input{
				width: 240px;
			}
			.demo-ruleForm{
				width: 510px;
			}
			.team{
				position: relative;
			}
			.tips{
				color: #A5343C;
				position: absolute;
				font-size: 12px;
				right: -100px;
			}
			.btnList{
				span{
					border: 1px solid #A5343C;
					color: #A5343C;
					margin-right: 20px;
					border-radius: 5px;
					padding: 8px 20px;
					cursor: pointer;
					font-size: 16px;
				}
				.checked{
					background: #A5343C;
					color: #FFFFFF;
				}
			}
			.btn{
				text-align: center;
			}
		}
	}
}

</style>
